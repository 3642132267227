import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Ring } from '@uiball/loaders'
import { Input } from '@/components/ui/input'

type AnimatedInputProps = {
  id: string
  type?: string
  name: string
  label?: string
  value: string
  placeholders: string[]
  maxLength?: number
  required?: boolean
  buttonLabel: string
  buttonIcon: React.ReactNode
  buttonDisabled?: boolean
  buttonType?: 'button' | 'submit' | 'reset'
  loading?: boolean
  onChange: (value: string) => void
}

export const AnimatedInput: React.FC<AnimatedInputProps> = ({
  id,
  type = 'text',
  name,
  label,
  value,
  placeholders,
  maxLength,
  required,
  buttonLabel,
  buttonIcon,
  buttonDisabled,
  buttonType = 'button',
  loading = false,
  onChange,
}) => {
  const [placeholder, setPlaceholder] = useState('')
  const [placeholderArrayIndex, setPlaceholderArrayIndex] = useState(0)
  const [placeholderIndex, setPlaceholderIndex] = useState(0)

  const updatePlaceholder = useCallback(() => {
    if (!value) {
      setPlaceholder((prevPlaceholder) => {
        const currentPlaceholder = placeholders[placeholderArrayIndex]
        if (placeholderIndex >= currentPlaceholder.length) {
          setPlaceholderIndex(0)
          setPlaceholderArrayIndex(
            (prevIndex) => (prevIndex + 1) % placeholders.length
          )
          return ''
        } else {
          setPlaceholderIndex((prevIndex) => prevIndex + 1)
          return currentPlaceholder.slice(0, placeholderIndex + 1)
        }
      })
    }
  }, [value, placeholders, placeholderArrayIndex, placeholderIndex])

  useEffect(() => {
    const intervalId = setInterval(updatePlaceholder, 150)
    return () => clearInterval(intervalId)
  }, [updatePlaceholder])

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value)
    },
    [onChange]
  )

  const buttonProps = useMemo(
    () => ({
      type: buttonType,
      disabled: buttonDisabled || loading,
      className:
        'relative w-full sm:w-auto inline-flex items-center justify-center space-x-2 rounded-md sm:rounded-l-none sm:-ml-px border border-indigo-600 bg-indigo-600 px-4 py-3 sm:py-2 text-sm font-medium text-white hover:border-indigo-700 hover:bg-indigo-700 focus:outline-none focus:ring-1 focus:ring-indigo-500 transition-colors',
    }),
    [buttonType, buttonDisabled, loading]
  )

  return (
    <div className='mx-auto w-full max-w-2xl'>
      <label htmlFor={id} className='sr-only'>
        {label}
      </label>
      <div className='flex flex-col gap-2 rounded-md shadow-sm sm:flex-row sm:gap-0'>
        <Input
          id={id}
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          maxLength={maxLength}
          required={required}
          onChange={handleChange}
          className='w-full sm:rounded-r-none'
        />
        <button {...buttonProps}>
          {loading ? <Ring size={18} color='white' /> : buttonIcon}
          <span>{buttonLabel}</span>
        </button>
      </div>
    </div>
  )
}
